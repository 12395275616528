<template>
  <v-container>
    <v-row>
        <v-col cols="12">
            <v-card-title>Sklep Owczy</v-card-title>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card-title>Cennik</v-card-title>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(item, index) in list" :key="index">
                            <td>{{item.name}}</td>
                            <td>{{item.price}}zł</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="12">
            <v-col cols="12">
                <v-card-title>Wybierz z listy co dana osoba chce kupić</v-card-title>
                <v-select
                :items="listForSelect"
                label="Wybierz"
                @change="select($event)"
                ></v-select>
            </v-col>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card-title>Koszyk - {{total}} zł</v-card-title>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(item, index) in shoppingCart" :key="index">
                            <td>{{item.name}}</td>
                            <td>{{item.price}}zł</td>
                            <td>
                                <v-btn
                                @click="remove(item.id)"
                                class="red darken-4 white--text"
                                elevation="2"
                                >Usuń</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
    <v-row v-if="total != 0">
        <v-col cols="12">
            <v-btn
            @click="save()"
            block
            class="light-green darken-2 white--text"
            elevation="2"
            >Zapisz</v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    name: 'HelloWorld',
    components: {
      QrcodeStream,
    },
    data: () => ({
        list: [],
        listForSelect: [],
        shoppingCart: [],
        total: 0,
    }),
    watch: {
        shoppingCart(array) {
            console.log('->', array);
            this.total = 0;
            if (array.length == 0) {
                this.total = 0;
            } else {
                array.forEach(item => {
                    this.total += item.price;
                });
            }
        }
    },
    methods: {
        async getList() {
            const response = await axios.get(`/api/shop/list`);
            console.log(response.data);
            if (response.data) {
                this.list = response.data;
                for (const key in response.data) {
                        this.listForSelect.push(response.data[key].name)    
                }
            }
        },
        select(name) {
            const item = Object.entries(this.list).find((e) => e[1].name == name)[1];
            console.log(item);
            this.shoppingCart.push(item)
        },
        remove(id) {
            const index = Object.entries(this.shoppingCart).findIndex((e) => e[1].id == id);
            console.log('index', index);
            this.shoppingCart.splice(index, 1); 
        },
        async save() {
            const response = await axios.post(`/api/shop/save`, this.shoppingCart);
            console.log(response.data.saved);
            if (response.data.saved) {
                this.shoppingCart = [];
                this.total = 0;
            }
        }
    },
    mounted() {
        this.getList()
    }
  }
</script>
